<template>
  <div class="read-body">
    
    <div class="read-content">
      <!-- <button @click="haha">11</button> -->
      <div class="read-title">
        <h1>{{ content.chapterName }}</h1>
        <p class="text-info">
          <span>书名:《{{ $route.query.name }}》</span>
        </p>
      </div>
      <div class="txt-content epub_c h5_flip pen" ref="ScrollElCp" >
        <div class="read_c">
          <div class="book-text h5_mainbody_block"
            :style="{'whiteSpace':content.isRichText==1?'inherit':'break-spaces'}" v-html="content.content"></div>
        </div>
      </div>
      <div class="tools">
        <el-popover ref="chapter" popper-class="haha" placement="left" title="请选择章节" width="300" trigger="click">
          <div class="chapter" @scroll="scrollEvent">
            <div v-for="(item,index) in chapterList" :key="item.chapterId" class="chapter-list" @click="changeChapter(item,index)">
              <span class="es-1" :class="index===type?'active':''">{{ item.chapterName }}</span>
            </div>
          </div>
          <div class="btn" slot="reference">
            <i class="el-icon-s-operation" style="font-size: 15px"></i>目录
          </div>
        </el-popover>
        <div class="btn" @click="toPage(false)">
          <i class="el-icon-d-arrow-left" style="font-size: 15px"></i>上一章
        </div>
        <div class="btn" @click="toPage(true)">
          <i class="el-icon-d-arrow-right" style="font-size: 15px"></i>下一章
        </div>
      
       
      </div>
    </div>
  </div>
</template>

<script>
import { getContent, getChapter } from "@/api";
// import vueQr from 'vue-qr'
// import "@/assets/style/book-style.css"
export default {
  name: "read",

  data() {
    return {
      content: {},
      chapterList: [],
      type:0,
      scrollTop:0

      // bookId: null,
      // chapter: null,
      // text: "<div>asdfasfa\nsfasdfasdfafasdfaasdfasfasfasdfasdfafasdfaasdfasfasfasdfasdfafasdfaasdfasfasfasdfasdfafasdfaasdfasfasfasdfasdfafasdfa</div>啊是的发送到发送阿萨达阿斯顿发阿斯顿发阿萨的发送发送到发送后阿萨啊是的发送到发送阿萨达阿斯顿发阿斯顿发阿萨的发送发送到发送后阿萨啊是的发送到发送阿萨达阿斯顿发阿斯顿发阿萨的发送发送到发送后阿萨",
    };
  },
  created() {
    // this.$refs.scrollRef.scrollTop = 0;
    const { name } = this.$route.query
    window.document.title = name;
    this.getContentDetail()
    this.getChapter()
   
  },
 
  methods: {
   
    scrollEvent(e) {
      console.log(e.srcElement.scrollTop || e.target.scrollTop) // 获取目标元素的滚动高度
    },
    changeChapter(item,index){
      if(item.chapterId == this.$route.query.chapter)return
        this.$router.push({
          path: '/phone',
          query: {
            id: this.content.bookId,
            chapter: item.chapterId,
            name: this.$route.query.name  
          }
        })
        this.getContentDetail()
        this.getChapter()
    },
    getChapter() {
      getChapter(this.$route.query.id).then(res => {
        // console.log(res);
        this.chapterList = res.data
        console.log(this.$route.query.chapter);
        this.type = res.data.findIndex((item) => item.chapterId == this.$route.query.chapter)
       

      })
      setTimeout(()=>{
      this.$refs['chapter'].doClose()
      },500)
    },
    toPage(status) {
      // console.log(11);
     
      
      // setTimeout(() => {
       
        this.getContentDetail(status)
      
        // this.$router.go(0)
      // }, 100)


    },
    getContentDetail(next = "") {
      const { id, chapter } = this.$route.query
      getContent({ bookId: id, chapterId: chapter, next }).then(res => {
        if (res.code === 200) {
          this.content = res.data
          this.$refs.ScrollElCp.scrollTop=0

          this.$router.push({
            path: '/phone',
            query: {
              id: this.content.bookId,
              chapter: this.content.chapterId,
              name: this.$route.query.name
            }
          })
          this.getChapter()
          
        } else {
          // console.log(111);
          // this.$notify.info({
          //   title: '11',
          //   message: '222'
          // })
        }
      })

    }
  },
};
</script>
<style scoped>@import "../../assets/style/book-style.css";</style>
<style lang="scss" scoped>


.active {
  color:red;
  padding: 5px 10px; 
  border-radius: 10px;
  border: 2px dashed red;
}
.chapter {
  // font-size: 200px;

  max-height:500px;
  // height: ;
  overflow-y: scroll;
  display: flex;
  // justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  .chapter-list {
    cursor: pointer;
    padding: 5px 10px;
    margin: 10px 0;
    width: 100%;
    line-height: 30px;
    span {
      padding: 5px 10px;
    }
    
  }
}

.read-body {
  // width: 100vw;
  height: 100vh;
  background-color: #ede8d5;

  .read-content {
    // overflow-y: scroll;
    position: relative;
    margin: 0 auto;
    box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);
    background-color: #f6f4ec;
    // width: 800px;
    height: 100vh;

    .read-title {
      width: 88%;
      margin: 0 auto;
      padding: 0 0 10px 10px;
      border-bottom: 1px solid #e3e1d9;

      h1 {
        padding-top: 24px;
        font: 26px/1 "Microsoft YaHei";
        margin-bottom: 10px;
        color: #000;
      }

      .text-info {
        color: #999;
        font: 12px/1.8 "Microsoft YaHei";
      }
    }

    .txt-content {
      font-size: 16px;
      padding-top: 1em;
      overflow-y: scroll;
      
      height: 85%;


      .book-text {
        width: 85%;
        margin: 0 auto;
        // white-space: pre-line;
         word-break: break-all;
         font-size: 18px;
         line-height: 50px;
      }
    }

    .tools  {
      position: absolute;
      // right: -66px;
      top:150px;
      z-index: 9;
      

      .btn {
        font-size: 12px;
        cursor: pointer;
        width: 40px;
        height: 40px;
        background-color: #f6f4ec;
        margin-bottom: 20px;
        box-shadow: 0 0 5px #bbb;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        // &:hover {
        //   background-color: #d7d0ac;
        // }
      }
    }
  }
}

</style>
